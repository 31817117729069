<!-- eslint-disable -->

<template>
  <div>
    <template v-if="isDataLoading">
      <slot name="loading">
        Loading...
      </slot>
    </template>
    <div v-else-if="data.length === 0" class="alert alert-warning" role="alert">
      {{ noDataWarningText }}
    </div>
    <div v-else-if="getNumOfCells() > numOfCellsLimitation" class="alert alert-warning" role="alert">
      {{ overNumOfCellsWarningText }}
    </div>
    <table v-else class="blueTable" :id="tableId">
      <!-- Table header -->
      <thead>
        <template v-for="rowIndex in [0, 1]">
          <tr v-if="rowIndex == 0" :key="`q_${rowIndex}`">
            <th :colspan="cols.length + 1">
              <span v-if="renderHTML" v-html="question"></span>
              <div v-else>{{ question }}</div>
            </th>
          </tr>
          <tr v-if="colFields.length > 0" class="bg-light" :key="`t_${rowIndex}`">
            <!-- Top left dead zone -->
            <th v-if="rowIndex == 0">Question</th>
            <th v-else-if="rowIndex == 1">Option</th>
            <th v-else></th>

            <!-- Column headers -->
            <th v-for="(col, colIndex) in cols" :key="JSON.stringify(col)" :colspan="calculateSpanSize(col, cols, colIndex, rowIndex)" v-if="calculateSpanSize(col, cols, colIndex, rowIndex) > 0">
              <template>
                <span v-if="renderHTML" v-html="computeDisplayText(cols[colIndex][rowIndex])"></span>
                <div v-else>{{ computeDisplayText(cols[colIndex][rowIndex]) }}</div>
              </template>
            </th>
          </tr>
        </template>
      </thead>

      <!-- Table body -->
      <tbody>
        <tr v-for="(row, rowIndex) in rows" :key="JSON.stringify(row)" v-if="row[0] != 'Grand_Total_Internal'">
          <!-- Row headers -->
          <th v-for="(rowField, rowFieldIndex) in rowFields" :key="'header-row-' + rowField.label" :rowspan="spanSize(rows, rowFieldIndex, rowIndex)"  v-if="(rowField.showHeader === void 0 || rowField.showHeader) && spanSize(rows, rowFieldIndex, rowIndex) !== 0" class="text-left" >
            <slot v-if="rowField.headerSlotName" :name="rowField.headerSlotName" v-bind:value="row[rowFieldIndex]">
              Missing slot <code>{{ rowField.headerSlotName }}</code>
            </slot>
            <template v-else>
              <span v-if="renderHTML" v-html="computeDisplayText(row[rowFieldIndex])"></span>
              <div v-else>{{ computeDisplayText(row[rowFieldIndex]) }}</div>
            </template>
          </th>
          <!-- Values -->
          <td v-for="(col, colIndex) in cols" :key="JSON.stringify(col)" class="text-right">
            <slot v-if="$scopedSlots.value" name="value" v-bind:value="values[JSON.stringify({ col, row })]" />
            <template v-else>{{ computeDisplayValue(config, values, row, col) }}</template>
            <!-- <template v-else>{{ JSON.stringify({ col, row }) }}</template> -->
          </td>
        </tr>
        <!-- <tr>
          <th>Grand Total</th>
          <template v-for="(col, colIndex) in cols">
            <th v-if="calculateSpanSize(col, cols, colIndex, 0) > 0" :key="`colIdx_${colIndex}`" :colspan="calculateSpanSize(col, cols, colIndex, 0)">
              {{ computeDisplayValue(config.showPercentage, values[JSON.stringify({ col, row: {0: 'Grand_Total_Internal'} })], values[computeColumnAndRowKey(col, { 0: 'Grand_Total_Internal' })]) }}
            </th>
          </template>
        </tr> -->
        <tr></tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */

import naturalSort from 'javascript-natural-sort'
import Util from './util'
import XLSX from 'xlsx';

export default {
  props: {
    questionValues: Object,
    config: Object,
    question: String,
    tableId: String,
    data: {
      type: Array,
      default: () => []
    },
    rowFields: {
      type: Array,
      default: () => []
    },
    colFields: {
      type: Array,
      default: () => []
    },
    reducer: {
      type: Function,
      default: (sum, item) => sum + 1
    },
    overNumOfCellsWarningText: {
      type: String,
      default: 'Too many cells. Please reduce the num of rows / cols.'
    },
    numOfCellsLimitation: {
      type: Number,
      default: 10000
    },
    noDataWarningText: {
      type: String,
      default: 'No data to display.'
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    renderHTML: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // Watched target properties for re-calculation
    calculationTriggers: function () {
      return [this.rowFields, this.colFields, this.reducer]
    },
    // Reversed props for footer iterators
    colFieldsReverse: function () {
      return this.colFields.slice().reverse()
    },
    rowFieldsReverse: function () {
      return this.rowFields.slice().reverse()
    },
    // Number of col header rows
    colHeaderSize: function () {
      return this.colFields.filter(colField => colField.showHeader === void 0 || colField.showHeader).length
    },
    // Number of col footer rows
    colFooterSize: function () {
      return this.colFields.filter(colField => colField.showFooter).length
    },
    // Number of row header columns
    rowHeaderSize: function () {
      return this.rowFields.filter(rowField => rowField.showHeader === void 0 || rowField.showHeader).length
    },
    // Number of row footer columns
    rowFooterSize: function () {
      return this.rowFields.filter(rowField => rowField.showFooter).length
    },
    // Index of the first column field header to show - used for table header dead zones
    firstColFieldHeaderIndex: function () {
      return this.colFields.findIndex(colField => colField.showHeader === void 0 || colField.showHeader)
    },
    // Index of the first column field footer to show - used for table footer dead zones
    firstColFieldFooterIndex: function () {
      return this.colFieldsReverse.findIndex(colField => colField.showFooter)
    }
  },
  methods: {
    computeDisplayText(text) {
      return typeof text === 'string' && text.split("__$$__")[1] ? text.split("__$$__")[1] : text
    },
    computeColumnAndRowKey(colText, rowText) {
      return JSON.stringify({ col: colText, row: rowText })
    },
    computeDisplayValue(config, values, row, col) {
      let key = JSON.stringify({ col, row })
      let value = values[key]
      let total = 100

      // always return base count
      if (JSON.stringify(row) == JSON.stringify({ 0: "Base (Count)" })) {
        return value
      }

      if (this.computeColumnAndRowKey(col, { 0: "Base (Count)" }) != this.computeColumnAndRowKey(col, row)) {
        if (config.basePercentageType == "column") {
          total = values[this.computeColumnAndRowKey(col, { 0: "Base (Count)" })]
        } else if (config.basePercentageType == "table") {

          // group question ? use base same as row : normal
          if (this.config.multipleChoiceQuestions[this.question]) {
            total = values[this.computeColumnAndRowKey({ 0: "Sample Size", 1: "Total" }, row)]
          } else {
            total = values[this.computeColumnAndRowKey(col, { 0: "Grand_Total_Internal" })]
          }

        } else if (config.basePercentageType == "row") {
          total = values[this.computeColumnAndRowKey({ 0: "Sample Size", 1: "Total" }, row)]
        }
      }

      if (config.showPercentage && total > 0) {
        return Math.round((value / total) * 100)
      }

      return value
    },
    getNumOfCells: function () { // this.cols / this.rows are not reactive
      return this.cols.length * this.rows.length
    },
    calculateCols: function () {
      const newCols = []

      if (this.colFields.length > 0) {
        newCols.push({ 0: "Sample Size", 1: "Total" })

        this.colFields.forEach((col, depth) => {
          let values = []
          if (col.label && this.config.multipleChoiceQuestions[col.label]) {
            for (let o in this.config.multipleChoiceQuestions[col.label]) {
              values.push(o.split("__$$__")[1])
            }

            values = [ ...new Set(values)].sort(naturalSort)
          } else {
            const getter = this.colFields[depth].getter
            const sort = this.colFields[depth].sort || naturalSort
            values = [...new Set(this.data.map(getter))].sort(sort)
          }

          values.forEach(value => {
            // Build new filter hash
            if (value != undefined && value != null && value != "") {
              newCols.push({ 0: col.label, 1: value })
            }
          })
        })
      } else {
        newCols.push({})
      }

      return newCols
    },
    calculateRows: function () {
      let rows = []
      
      rows.push({ 0: "Base (Count)" }) // Total response count
      rows.push({ 0: "Grand_Total_Internal" }) // Base count to calculate percentage
      
      const extractRowsRecursive = (data, depth, filters, getter = this.rowFields[depth].getter) => {
        const sort = this.rowFields[depth].sort || naturalSort
        const values = [...new Set(data.map(getter))].sort(sort)

        values.forEach(value => {          
          // Build new filter hash
          if (value != undefined && value != null && value != "") {
            const valueFilters = { ...filters, [depth]: value }
            const filteredData = Util.filterDataByValue({ data, getter, filter: value })
            // Recursive call
            if (depth + 1 < this.rowFields.length) {
              extractRowsRecursive(filteredData, depth + 1, valueFilters)
            } else {
              rows.push(valueFilters)
            }
          }
        })
      }

      if (this.rowFields.length > 0) {
        extractRowsRecursive(this.data, 0, {})

        // Check if it is a multiple choice question
        for (let o in this.config.multipleChoiceQuestions[this.question]) {
          let getters = [{ getter: item => item[o], label: o }]
          extractRowsRecursive(this.data, 0, {}, getters[0].getter)
        }

      } else {
        rows.push({})
      }

      return rows
    },
    // Get data filtered
    filteredData: function ({ data = [], colFilters = {}, rowFilters = {} }) {
      // Prepare getters
      const colGetters = {}
      const rowGetters = {}

      for (const depth in colFilters) {
        colGetters[depth] = this.colFields[depth] ? this.colFields[depth].getter : undefined
      }

      for (const depth in rowFilters) {
        rowGetters[depth] = this.rowFields[depth] ? this.rowFields[depth].getter : undefined
      }

      // Filter data with getters
      return data.filter(item => {        
        let keep = true

        // If contains crossed question
        if (Object.keys(colFilters).length > 0) {
          let title = colFilters[0]
          let answer = colFilters[1]
          
          if (this.config.multipleChoiceQuestions[title]) {
            title = answer = `${title}__$$__${answer}`
          }

          if (item[title] != answer) {
            keep = false
          }
        }

        if (keep) {
          for (const depth in rowFilters) {
            // Single choice question || multiple choice question
            if (item[this.question] && rowFilters[depth] == "Base (Count)") {
              break
            } else if ((this.config.multipleChoiceQuestions[this.question] && rowFilters[depth] == "Base (Count)")) {
              
              // Calculate the base for filtered question
              for (let answeredQuestion in this.config.multipleChoiceQuestions[this.question]) {
                keep = false

                if (item[answeredQuestion]) {
                  keep = true
                  break
                }
              }

              break
            }

            // Filter multiple choice questions
            if (item[rowFilters[depth]] && rowFilters[depth]) {
              break
            }

            if (rowGetters[depth](item) !== rowFilters[depth]) {
              keep = false
              break
            }
          }
        }

        return keep
      })
    },
    calculateSpanSize(col, cols, colIndex, rowIndex) {
      let colTitle = col[0]

      let duplicateColTitle = cols.filter(col => col[0] == colTitle)

      if (rowIndex == 0) {
        if (colIndex == 0 || (cols[colIndex - 1][0] != colTitle)) {
          return duplicateColTitle.length
        }         
      } else {
        return 1
      }
    },
    // Get colspan/rowspan size
    spanSize: function (values, fieldIndex, valueIndex) {
      // If left value === current value
      // and top value === 0 (= still in the same top bracket)
      // The left td will take care of the display
      if (valueIndex > 0 &&
        values[valueIndex - 1][fieldIndex] === values[valueIndex][fieldIndex] &&
        (fieldIndex === 0 || (this.spanSize(values, fieldIndex - 1, valueIndex) === 0))) {
        return 0
      }

      // Otherwise, count entries on the right with the same value
      // But stop if the top value !== 0 (= the top bracket has changed)
      let size = 1
      let i = valueIndex
      while (i + 1 < values.length &&
        values[i + 1][fieldIndex] === values[i][fieldIndex] &&
        (fieldIndex === 0 || (i + 1 < values.length && this.spanSize(values, fieldIndex - 1, i + 1) === 0))) {
        i++
        size++
      }

      return size
    },
    // Called when cols/rows have changed => recompute values
    computeValues: function () {
      // Remove old values
      this.values = {}

      if (this.getNumOfCells() > this.numOfCellsLimitation) {
        // do not calculate if too many values
        return
      }

      // Compute new values
      this.rows.forEach(row => {
        const rowData = this.filteredData({ data: this.data, rowFilters: row })

        this.cols.forEach(col => {
          const lookupCol = (JSON.stringify(col) == JSON.stringify({0: "Sample Size", 1: "Total"})) ? {} : col
          let data = this.filteredData({ data: rowData, colFilters: lookupCol })
          const key = JSON.stringify({ col, row })
          const value = data.reduce(this.reducer, 0)
          this.values[key] = value
          Util.assignToObject(this.questionValues, this.question, key, value)
        })
      })

      // Compute new column total
      for (let row of this.rows) {
        for (let col of this.cols) {
          const key = JSON.stringify({ col, row })

          // Get base from respondents count
          if (key == this.computeColumnAndRowKey(col, { 0: 'Base (Count)' })) {     
            // Multiple choice question ? get row total : column total
            const value = this.config.multipleChoiceQuestions[this.question] ? Util.computeRowTotal(this.values, this.rows, this.cols, {0: 'Base (Count)'}, col) : Util.computeColumnTotal(this.values, this.rows, this.cols, row, col)
            this.values[key] = value
            Util.assignToObject(this.questionValues, this.question, key, value)

            break
          }

          // Internal total sum
          if (key == this.computeColumnAndRowKey(col, { 0: 'Grand_Total_Internal' })) {
            const value = Util.computeRowTotal(this.values, this.rows, this.cols, {0: 'Base (Count)'}, col)

            // Swap base count and grant_total_internal for multiple choice question w/o cross
            if (this.config.multipleChoiceQuestions[this.question]) {
              let multipleBaseCountKey = JSON.stringify({ col: { 0: "Sample Size", 1: "Total"}, row: { 0 : "Base (Count)" }})
              this.values[multipleBaseCountKey] = value
            }

            this.values[key] = value
            Util.assignToObject(this.questionValues, this.question, key, value)
          }
        }
      }
    },
    saveTableWithText (tableId) {
      let table = document.getElementById(tableId)
      let workbook = XLSX.utils.table_to_book(table)
      
      XLSX.writeFile(workbook, `${Util.getFilenameByDate(new Date())}.xlsb`);
    },
  },
  watch: {
    calculationTriggers: function () {
      this.rows = this.calculateRows()
      this.cols = this.calculateCols()
      this.computeValues()
    }
  },
  created: function () {
    this.rows = this.calculateRows()
    this.cols = this.calculateCols()
    this.computeValues()
  }
}
</script>

<style lang="scss" scoped>
@import "./../../assets/styles/tableStyle.scss";
</style>