export default {
  toDoubleDigits (num) {
    return ('0' + num).slice(-2)
  },
  
  getFilenameByDate (date) {
    let y = date.getFullYear()
    let m = this.toDoubleDigits(date.getMonth() + 1)
    let d = this.toDoubleDigits(date.getDate())
    let h = this.toDoubleDigits(date.getHours())
    let _m = this.toDoubleDigits(date.getMinutes())
    let s = this.toDoubleDigits(date.getSeconds())
    
    return y + m + d + '-' + h + _m + s
  },

  computeRowTotal(values, rows, cols, toMatchRow, toMatchCol) {
    let value = 0

    for (let row of rows) {
      if (row[0] == toMatchRow[0]) {
        for (let col of cols) {
          if (col[0] == toMatchCol[0]) {
            value += values[JSON.stringify({ col, row })]
          }
        }
      }
    }

    return value
  },

  computeColumnTotal(values, rows, cols, toMatchRow, toMatchCol) {
    let value = 0

    for (let row of rows) {
      if (row != toMatchRow) {
        for (let col of cols) {
          if (col == toMatchCol) {
            value += values[JSON.stringify({ col, row })]

          }
        }
      }
    }

    return value
  },

  filterByQuestionOption(data, question, option) {
    let newData = []

    data.forEach(row => {
      if (row[question] && row[question] == option) {
        newData.push(row)
      }
    })

    return newData
  },

  assignToObject(masterQuestions, question, key, value) {
    if (masterQuestions[question] != undefined) {
      masterQuestions[question][key] = value
    } else {
      masterQuestions[question] = {
        [key]: value
      }
    }
  },

  filterDataByValue: function ({ data = [], getter, filter = undefined }) {
    // Filter data with getters
    if (filter !== void 0) {
      return data.filter(item => getter(item) === filter)
    } else {
      return data.slice()
    }
  }
}
