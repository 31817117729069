<template>
  <v-container grid-list-xl fluid tile>
    <v-expansion-panels :value="[0]" multiple accordion>
      <v-expansion-panel>
        <v-expansion-panel-header><h2 class="page-title">Cross Tab</h2></v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- { title: 'Pivot Table', value: 'pivotTable' } -->
          <v-select v-if="false"
            :items="[ { title: 'Cross Tab', value: 'crossTab' } ]"
            item-text="title"
            item-value="value"
            label="Table Type"
            v-model="config.tableType"
            deletable-chips
            outlined
            disabled
          />

          <v-row>
            <v-col cols="6">
              <v-file-input v-if="config.chooseFromFile == true"
                v-model="config.fileToUpload"
                accept=".xlsx"
                @change="e => selectedFile = e"
                label="Upload an Excel File (.xlsx)"
              />

              <v-autocomplete v-if="config.chooseFromFile == false"
                :items="projects"
                item-text="name"
                item-value="_id"
                label="Select"
                hint="Pick a project to show the dashboard"
                v-on:change="handleProjectChange"
                persistent-hint
                return-object
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" v-if="config.tableType != null">
              <v-row>
                <!-- <v-switch class="ma-2"
                  v-model="config.chooseFromFile"
                  label="Upload From File" 
                  :disabled="true"
                /> -->

                <v-switch v-if="config.chooseFromFile == true" class="ma-2"
                  v-model="config.mergedQuestionHeader"
                  label="Merged Question Header"
                />

                <v-switch v-if="config.tableType == 'crossTab'" class="ma-2"
                  v-model="config.showPercentage"
                  label="Calculate Percentage" 
                />
              </v-row>

              <v-radio-group v-if="config.showPercentage"
                v-model="config.basePercentageType"
                :mandatory="true"
                row
              >
                <v-radio label="Column Percentage" value="column" />
                <v-radio label="Row Percentage" value="row" :disabled="selectedCrossTabs.length == 0 ? true : false" />
                <v-radio label="Table Percentage" value="table" :disabled="selectedCrossTabs.length == 0 ? true : false" />
              </v-radio-group>
            </v-col>
          </v-row>

          <v-autocomplete v-if="loaded && questions.length > 0"
            deletable-chips
            multiple
            chips
            return-object
            :items="questions"
            label="Append Question(s)"
            v-model="selectedCrossTabs"
            outlined
          ></v-autocomplete>

          <v-btn v-if="config.chooseFromFile == true"
            color="primary"
            class="mr-2"
            @click="loadFile()"
            :disabled="!selectedFile"
          >Load File</v-btn>

          <v-btn v-if="loaded && questions.length > 0"
            color="primary"
            @click="computeCrossTabs(selectedCrossTabs)"
          >Compute Questions</v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    
    <div v-if="loaded">
      <v-btn @click="exportTable('tableWrapper')" class="success mt-4" v-if="data.length > 0">Export All Tables</v-btn>

      <table id="tableWrapper">
        <HandyScroll>
          <template v-for="(question, q_i) of questions">
            <pivot
              class="py-2"
              :key="`question_${q_i}`"
              :config="config"
              :tableId="`${question}_${q_i}`"
              :question="question"
              :data="data"
              :reducer="reducer"
              :showSettings="defaultShowSettings"
              :questionValues="questionValues"
              :availableFields="[]"
              :rowFields="generateField(question)"
              :colFields="colFields"
              :fieldsOrder="fieldsOrder"
              :renderHTML="renderHTML"
              @changeRenderHTML="changeRenderHTML"
            />
          </template>
        </HandyScroll>
      </table>
    </div>
  </v-container>
</template>

<script>
import { DataService as BuyerDataService } from "./../services/BuyerDataService";
import Pivot from './../components/DataProcessor/Pivot.vue';
import XLSX from 'xlsx';
import Util from './../components/DataProcessor/util'
import HandyScroll from "vue-handy-scroll";

const buyerDataService = new BuyerDataService();

export default {
  components: { Pivot, HandyScroll },
  data: () => {
    return {
      // File
      selectedFile: undefined,

      data: [],
      rowFields: [],
      colFields: [],
      fieldsOrder: {},
      questions: [],
      questionValues: {},
      reducer: (sum) => sum += 1, // item.count
      defaultShowSettings: false,
      loaded: false,
      selectedCrossTabs: [],
      config: {
        tableType: 'crossTab',
        basePercentageType: 'column',
        chooseFromFile: true,
        mergedQuestionHeader: false,
        multipleChoiceQuestions: {}
      },

      // Projects
      projects: [],
      renderHTML: true,
    }
  },
  filters: {
    number: function (value) {
      return value.toLocaleString()
    },
    capitalize: function (value) {
      return value.replace(/\b\w/g, l => l.toUpperCase())
    }
  },
  created() {
    this.checkUserPermission()
    setInterval(this.verifyUser, 10 * 60 * 1000);

    this.verifyUser()
  },
  methods: {
    checkUserPermission() {
      let user = this.$store.state.buyerUser

      if (user && user.permissions && !user.permissions.includes("CROSSTAB")) {
        alert("You do not have the specific license accociate to your account. Please contact your Customer Success Manager")
        this.$router.push({ name: 'Root' })
      } else {
        this.loaded = true
      }
    },
    verifyUser() {
      buyerDataService.whoAmI()
    },
    exportTable(tableId) {
      this.renderHTML = false

      this.$nextTick (() => {
        let table = document.getElementById(tableId)
        let workbook = XLSX.utils.table_to_book(table)
        
        XLSX.writeFile(workbook, `${Util.getFilenameByDate(new Date())}.xlsb`);
        this.renderHTML = true
      })
    },
    getTables() {
      let tables = document.getElementsByClassName("blueTable")

      let workbook = {
        SheetNames: ["Sheet1"],
        Sheets: {
          Sheet1: null
        }
      }

      let ws = {}

      for (let table of tables) {
        let tableSheet = XLSX.utils.table_to_sheet(table)
        let tableJson = XLSX.utils.sheet_to_json(tableSheet)

        ws = XLSX.utils.sheet_add_json(ws, [{ A1: { t: "s", v: "Question" } }], {skipHeader: false, origin: -1 })
        ws = XLSX.utils.sheet_add_json(ws, tableJson, {skipHeader: true, origin: -1 })
        ws = XLSX.utils.sheet_add_json(ws, [{ A1: { t: "s", v: "" } }], {skipHeader: true, origin: -1 })
      }

      workbook.Sheets.Sheet1 = ws

      XLSX.writeFile(workbook, `${Util.getFilenameByDate(new Date())}.xlsb`);
    },
    computeCrossTabs(selectFields) {
      let newSelectedFields = []

      for (let question of selectFields) {
        newSelectedFields.push({
          getter: item => item[question],
          label: question
        })
      }
      
      this.colFields = newSelectedFields

      this.loaded = false;
      this.$nextTick (() => {
        this.loaded = true;
      });
    },
    generateField (question) {
      return [
        {
          getter: item => item[question],
          label: question
        }
      ]
    },

    // Dashboard Data
    getProjects() {
      this.$setLoader();

      // mobileDataService.getProjects().then(r => {        
      //   this.projects.push(...r.projects);
      //   this.$clearLoader()
      // })

      buyerDataService.getProjects({ userId: this.$store.state.userProfile._id }).then(r => {        
        this.projects.push(...r);
        this.$clearLoader()
      })
    },
    handleProjectChange: async function(selectedValue) {
      this.$setLoader()
      this.loaded = false
      
      buyerDataService.getSelectedProject(selectedValue._id).then(r =>{
        if (this.debug) {
          console.log(r[0])
        }

        this.getDashboardData({ projectId: selectedValue._id });
      })
    },

    webSocketSeviceFn({ projectId, toMatchQuery, frontendFiltering = false, token = null }) {
      this.loadedTabs = []
      this.useFilterType = (frontendFiltering == true) ? 1 : 2

      this.webSocketService = new WebSocket(process.env.VUE_APP_SOCKET_URL)
      this.webSocketService.addEventListener("message", res => {
        let data = res.data ? JSON.parse(res.data) : {}

        if (data.uniqueId) {
          this.webSocketService.send(JSON.stringify({ projectId, toMatchQuery, frontendFiltering, token, getCrossTabData: true }))
        }

        if (data.result) {
          if (Object.keys(data.result).length >= 0) {
            this.selectedCrossTabs = []
            this.questions = data.questions
            this.data = data.result
            this.loaded = true
          }

          this.$clearLoader()
          this.webSocketService.close()
        }

        if (data.error && data.error == true) {
          this.$store.state.dialogMessageTitle = "Error"
          this.$store.state.dialogMessageText = data.message
          this.$store.state.showDialogMessage = true

          this.$clearLoader()
          this.webSocketService.close()
        }
      })
    },

    getDashboardData({ projectId, toMatchQuery = undefined, frontendFiltering = false }) {
      let token = window.$cookies.get("izcToken")

      if (token != null) {
        this.$setLoader();
        this.config.mergedQuestionHeader = false
        this.webSocketSeviceFn({ projectId, toMatchQuery, frontendFiltering, token })
      } else {
        alert("Session Expired. Please Login Again.")
        this.$router.push({ name: "Login" });   
      }
    },

    changeRenderHTML(status) {
      this.renderHTML = status
    },
    loadFile() {
      function nextExcelColumnString(str) {
        if (! str)
          return 'A'  // return 'A' if str is empty or null

        let tail = ''
        let i = str.length -1
        let char = str[i]
        // find the index of the first character from the right that is not a 'Z'
        while (char === 'Z' && i > 0) {
          i--
          char = str[i]
          tail = 'A' + tail   // tail contains a string of 'A'
        }
        
        if (char === 'Z')   // the string was made only of 'Z'
          return 'AA' + tail
        // increment the character that was not a 'Z'
        return str.slice(0, i) + String.fromCharCode(char.charCodeAt(0) + 1) + tail
      }

      if (this.selectedFile) {
        this.$setLoader();
        let mergedQuestionHeader = this.config.mergedQuestionHeader

        let reader = new FileReader();
        reader.onload = () => {
          var bstr = reader.result;

          var workbook = XLSX.read(bstr, {type:'binary'});
          var sheetNameList = workbook.SheetNames;

          // Force read the first sheet only
          sheetNameList.length = 1

          var data = [];

          var uniqueL1Headers = {}
          var L1Headers = {}
          var L2Headers = {};
          var multipleChoiceQuestions = {}
          
          sheetNameList.forEach(function(y) {
            var worksheet = workbook.Sheets[y];

            for (let z in worksheet) {
              if (z[0] === '!') { continue  }
              //parse out the column, row, and value
              var tt = 0;
              
              for (var i = 0; i < z.length; i++) {
                if (!isNaN(z[i])) {
                  tt = i;
                  break;
                }
              }

              // y = worksheet name
              // z = column and row (A3, B3, C3)
              // tt = length of column name (AA = 2, AAA = 3)
              // col = column position (A, B, C)
              // row = row index (1, 2, 3)

              var col = z.substring(0,tt);
              var row = parseInt(z.substring(tt));
              var value = worksheet[z].v;

              // Get unique questions
              if (row == 1 && value) {
                uniqueL1Headers[col] = value

                if (mergedQuestionHeader == false) {
                  L1Headers[col] = value  
                }

                continue;
              }

              if (mergedQuestionHeader == true && row == 2 && value) {
                // compute question to col
                let question = worksheet[`${col}${row-1}`] ? worksheet[`${col}${row-1}`].v : undefined
                L1Headers[col] = question ? question : Object.values(L1Headers).slice(-1)[0]
                
                // set question / option header
                L2Headers[col] = value;

                continue;
              }

              if (!data[row]) {
                data[row]={}
              }
              
              // Custom value for merged question
              let currentL1HeaderExists = worksheet[`${col}1`] && worksheet[`${col}1`].v ? true : false
              let nextL1HeaderExists = worksheet[`${nextExcelColumnString(col)}1`] && worksheet[`${nextExcelColumnString(col)}1`].v ? true : false
              let nextL2HeaderExists = worksheet[`${nextExcelColumnString(col)}2`] && worksheet[`${nextExcelColumnString(col)}2`].v ? true : false

              // MergedQuestionHeader || Current and next exists || current exists and next not empty
              if (mergedQuestionHeader == false || (currentL1HeaderExists && nextL1HeaderExists) || (currentL1HeaderExists && !nextL1HeaderExists & !nextL2HeaderExists)) {
                data[row][L1Headers[col]] = value && value != "" ? value : undefined
              } else {
                data[row][`${L1Headers[col]}__$$__${L2Headers[col]}`] = value && value != "" ? `${L1Headers[col]}__$$__${L2Headers[col]}` : undefined

                // combined questions
                if (!multipleChoiceQuestions[L1Headers[col]]) {
                  multipleChoiceQuestions[L1Headers[col]] = {}
                }

                multipleChoiceQuestions[L1Headers[col]][`${L1Headers[col]}__$$__${L2Headers[col]}`] = `${L1Headers[col]}__$$__${L2Headers[col]}` 
              }
            }

            //drop those first two rows which are empty
            if (mergedQuestionHeader == true) {
              data.shift();
            }
            
            data.shift();
            data.shift();
          });

          this.colFields = []
          this.questions = []
          this.data = []
          this.loaded = false
          this.config.multipleChoiceQuestions = multipleChoiceQuestions

          this.$nextTick (() => {
            this.questions = Object.values(uniqueL1Headers)
            this.data = data
            this.loaded = true
            this.$clearLoader();
          });
        };

        reader.readAsBinaryString(this.selectedFile);
      }
    },
  },
  watch: {
    "config.chooseFromFile": function() {
      this.selectedCrossTabs = []
    }
  }
}
</script>

<style lang="scss">
#tableWrapper {
  width: 100%;
  display: block;
  overflow-x: auto;
}
</style>
