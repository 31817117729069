<!-- eslint-disable -->

<template>
  <div>
    <PivotTab ref="pivottable" v-if="config.tableType == 'pivotTable'"
      :tableId="tableId" 
      :question="question"
      :config="config" 
      :data="data" 
      :row-fields="internal.rowFields" 
      :col-fields="internal.colFields"
      :reducer="reducer" 
      :no-data-warning-text="noDataWarningText"
      :is-data-loading="isDataLoading"
    >
      <!-- pass down scoped slots -->
      <template v-for="(slot, slotName) in $scopedSlots" :slot="slotName" slot-scope="{ value }">
        <slot :name="slotName" v-bind="{ value }"></slot>
      </template>
      <template slot="loading">
        <slot name="loading"></slot>
      </template>
    </PivotTab>

    <CrossTab ref="pivottable" v-if="config.tableType == 'crossTab'"
      :tableId="tableId" 
      :question="question"
      :questionValues="questionValues"
      :config="config" 
      :data="data" 
      :row-fields="internal.rowFields" 
      :col-fields="internal.colFields"
      :reducer="reducer" 
      :no-data-warning-text="noDataWarningText"
      :is-data-loading="isDataLoading"
      :renderHTML="renderHTML"
    >
      <!-- pass down scoped slots -->
      <template v-for="(slot, slotName) in $scopedSlots" :slot="slotName" slot-scope="{ value }">
        <slot :name="slotName" v-bind="{ value }"></slot>
      </template>
      <template slot="loading">
        <slot name="loading"></slot>
      </template>
    </CrossTab>

    <v-btn @click="downloadPivotTableData(tableId)" small>Export Table</v-btn>
  </div>
</template>

<script>
/* eslint-disable */

import CrossTab from './CrossTab.vue'
import PivotTab from './PivotTable.vue'
import naturalSort from 'javascript-natural-sort'

export default {
  name: 'vue-pivot',
  components: { PivotTab, CrossTab },
  model: {
    prop: 'fields',
    event: 'change',
  },
  props: {
    questionValues: Object,
    config: Object,
    tableId: String,
    question: String,
    data: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    },
    reducer: {
      type: Function,
      default: (sum) => sum + 1
    },
    showSettings: {
      type: Boolean,
      default: true
    },
    availableFieldsLabelText: {
      type: String,
      default: 'Available fields'
    },
    colsLabelText: {
      type: String,
      default: 'Columns'
    },
    rowsLabelText: {
      type: String,
      default: 'Rows'
    },
    hideSettingsText: {
      type: String,
      default: 'Hide settings'
    },
    showSettingsText: {
      type: String,
      default: 'Show settings'
    },
    noDataWarningText: {
      type: String,
      default: 'No data to display.'
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: Number,
      default: 300
    },
    availableFields: {
      type: Array,
      default: []
    },
    rowFields: {
      type: Array,
      default: []
    },
    colFields: {
      type: Array,
      default: []
    },
    fieldsOrder: {
      type: Object,
      default: {}
    },
    renderHTML: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      internal: {
        availableFields: this.availableFields,
        rowFields: this.rowFields,
        colFields: this.colFields,
        fieldsOrder: this.fieldsOrder
      },
      dragging: false
    }
  },
  created () {
    this._sortFields(this.internal.fieldsOrder)
  },
  watch: {
    'internal.availableFields': function () {
      this._emitPivotFieldsChange()
    },
    'internal.rowFields': function () {
      this._emitPivotFieldsChange()
    },
    'internal.colFields': function () {
      this._emitPivotFieldsChange()
    },
    'internal.fieldsOrder': function () {
      this._emitPivotFieldsChange()
    }
  },
  methods: {
    _start: function () {
      this.dragging = true
    },
    _end: function () {
      this.dragging = false
    },
    _resetCols () {
      this.internal.availableFields = [...this.internal.availableFields, ...this.internal.colFields]
      this.internal.colFields = []
    },
    _resetRows () {
      this.internal.availableFields = [...this.internal.availableFields, ...this.internal.rowFields]
      this.internal.rowFields = []
    },
    _emitPivotFieldsChange () {
      const value = {
        availableFields: this.internal.availableFields,
        rowFields: this.internal.rowFields,
        colFields: this.internal.colFields,
        fieldsOrder: this.internal.fieldsOrder
      }
      this.$emit('change', value)
    },
    downloadPivotTableData (tableId) {
      this.$emit('changeRenderHTML', false)

      this.$nextTick (() => {
        this.$refs.pivottable.saveTableWithText(tableId)
        this.$emit('changeRenderHTML', true)
      })
    },
    _sortFields (fieldsOrder) {
      const appendSortOption = function (fields) {
        return fields.map((field) => {
          if (fieldsOrder[field.label] === 'desc') {
            return { ...field, sort: (x, y) => naturalSort(y, x) }
          } else {
            let { sort, ..._field } = field // remove 'sort' key
            return _field
          }
        })
      }
      this.internal.colFields = appendSortOption(this.internal.colFields)
      this.internal.rowFields = appendSortOption(this.internal.rowFields)
    },
    _fieldClicked (label) {
      const orgVal = this.internal.fieldsOrder[label]
      if (orgVal) {
        // recreate instance for reactivity
        this.internal.fieldsOrder = { ...this.internal.fieldsOrder, [label]: undefined }
      } else {
        this.internal.fieldsOrder = { ...this.internal.fieldsOrder, [label]: 'desc' }
      }
      this._sortFields(this.internal.fieldsOrder)
    }
  }
}
</script>
